.sidebar {
    position: absolute;
    min-height: 100vh !important;
    width: 100%;
    z-index: 100;
    padding: 8px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
#sidebar-wrapper{
    min-height: 100vh !important;
    width: 100vw;
    margin-left: -1rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}
a.sidebar-link {
    margin: 1rem 0 1.5rem 0;
    text-decoration: none;
    cursor: pointer;
    display: block;
}
a.sidebar-selected {
    font-weight: 600;
    letter-spacing: -0.2px;
}
#page-content-wrapper {
    min-width: 0;
    width: 100%;
}