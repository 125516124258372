@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');


:root {
  --color-vivanta-1: #694aff;
  --color-vivanta-2: #3343D6;
  --color-vivanta-3: #b050e8ff;
  --color-vivanta-4: #fb6282ff;
  --color-vivanta-5: #f6956bff;
  --color-vivanta-6: #298bfeff;
  --color-vivanta-7: #1a1b54ff;
  --color-vivanta-gray: #5E5E5E;
  --color-vivanta-black: #212121;
  --color-vivanta-white: #FFFFFF;
}

html,body {
  font-family: 'Montserrat', sans-serif;
}

.under-navigation {
  padding-bottom: 70px;
}

.navbar.bg-vivanta,.bg-vivanta {
  background-color: var(--color-vivanta-1);
  color: var(--color-vivanta-white);
}

.navbar.bg-vivanta-2,.bg-vivanta-2 {
  background-color: var(--color-vivanta-2);
  color: var(--color-vivanta-white);
}

.navbar.bg-vivanta-gray,.bg-gray {
  background-color: var(--color-vivanta-gray);
  color: var(--color-vivanta-white);
}

.navbar.bg-vivanta .nav-link {
  color: var(--color-vivanta-white);
}

.navbar.bg-vivanta .navbar-toggler {
  color: var(--color-vivanta-1);
}
.navbar.bg-vivanta .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1.0)' strokeWidth='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.vivanta-header-logo {
  padding-right: 16px;
}

.vivanta-color-1 {
  color: var(--color-vivanta-1) !important;
}

.vivanta-color-2 {
  color: var(--color-vivanta-2) !important;
}

a {
  color: var(--color-vivanta-2) !important;
}

.box-body a {
  text-decoration: none;
}

.img-polaroid {
  border: 2px solid #ddd;
  border-radius: 4px;
  width: 100%;
  height: auto;
}

h1 {
  color: var(--color-vivanta-2) !important;
}

.documentation h3,a.anchor {
  font-weight: 600;
  color: var(--color-vivanta-2);
  margin-bottom: 1rem;
  margin-top: 2rem;
  text-decoration: none;
}

.documentation h5 {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--color-vivanta-7);
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.documentation p {
  margin-bottom: 1rem;
}

.documentation ul li,.documentation ol li {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.documentation em {
  display: inline-block;
  font-style: italic;
  font-weight: normal;
  background-color: var(--color-vivanta-3);
  color: white;
  padding: 0 0.5rem;
  font-size: 0.9rem;
  border-radius: 0.2rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
